
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-blog::v-deep {
    .extract {
        li {
            padding-bottom: 1em;
        }
    }
}
